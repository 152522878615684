import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
export default {
  name: "batteryModelAdd",
  mixins: [mixins],
  data() {
    return {
      edit: false,
      formModel: {
        id: "",
        name: "",
        standardVoltage: "",
        standardCapacity: "",
        modelSize: "",
        minVoltage: 0.0,
        maxVoltage: 0,
        maxMileage: 0
      },
      formRules: {
        name: [{
          required: true,
          message: '请输入电池型号!',
          trigger: 'blur'
        }],
        standardVoltage: [{
          required: true,
          message: '请输入标准电压!',
          trigger: 'blur'
        }],
        standardCapacity: [{
          required: true,
          message: '请输入标准容量!',
          trigger: 'blur'
        }],
        modelSize: [{
          required: true,
          message: '请输入尺寸大小!',
          trigger: 'blur'
        }],
        minVoltage: [{
          required: true,
          message: '请输入最小电压!',
          trigger: 'blur'
        }],
        maxVoltage: [{
          required: true,
          message: '请输入最大电压!',
          trigger: 'blur'
        }],
        maxMileage: [{
          required: true,
          message: '请输入最大行程距离!',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submitForm() {
      if (this.formModel.maxVoltage < this.formModel.minVoltage) {
        ElMessage.warning("最大电压必须大于等于最小电压！");
        return;
      }
      if (this.formModel.maxMileage <= 0) {
        ElMessage.warning("请输入最大行程距离必须大于0！");
        return;
      }
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.batteryModel.update(this.formModel).then(callBack);
          } else {
            this.$api.business.batteryModel.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.batteryModel.detail(this.$route.query.id).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
        } else if (res.code === 121601) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};